import axios from 'axios';
import { toast } from 'react-toastify';

import { getUserData, getUserToken } from './user';

const getBaseAPI = function (url: string) {
  const r: any = {},
    arr: string[] = url
      .replace('http://', '')
      .replace('https://', '')
      .split('*.');
  r.isWildcard = arr.length > 1;
  r.base = r.isWildcard ? (arr[1] === 'www' ? arr[2] : arr[1]) : arr[0];
  return r;
};

export const getAPIUrl = function (url: string): string {
  const baseAPI = getBaseAPI(url),
    arr = document.location.host.split('.app.konvix.com.br');
  let r = baseAPI.base;
  if (baseAPI.isWildcard) r = `${arr[0]}.${r}`;
  return `${document.location.protocol}//${r}`;
};

const api = axios.create({
  baseURL: getAPIUrl(String(process.env.REACT_APP_BASE_URL)),
});

const errorKeywords = ['[ERROR]', '[WARNING]', '[INFO]'];

api.interceptors.request.use(
  (config) => {
    const userToken = getUserToken();
    const userData = getUserData();

    const isRecovery = window.location.pathname.includes('recovery');

    if (
      (!userToken || !userData) &&
      window.location.pathname !== '/' &&
      !isRecovery
    )
      window.location.href = '/';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
let isToastDisplayed = false; //

api.interceptors.response.use(
  (response) => {
    /**
     * Adicionada tratativa de status code 207 para transmissão de NF
     * A transmissão possui etapas na sefaz, database e carregamento da DANFE
     * Como as etapas não dependem uma da outra para sucesso da operação (da transmissão) a tratavida deve ser feita separadamente
     */
    const { status, data } = response;
    const { message } = data;

    if (status === 207) {
      toast.warning(message);
    }
    window.dispatchEvent(new Event('NetworkEvent'));
    return response;
  },
  (error) => {
    if (!error.response) {
      window.dispatchEvent(new Event('NetworkErrorEvent'));

      if (!isToastDisplayed) {
        isToastDisplayed = true;

        setTimeout(() => {
          isToastDisplayed = false;
        }, 2000);
        toast.error(
          'Houve um problema ao conectar com o servidor, por favor, verifique sua conexão de internet e tente novamente.',
        );
      }

      return Promise.reject();
    }

    const { status, data } = error.response;

    let { message } = data;
    let arrMessage: string[];

    if (
      status === 400 &&
      message &&
      message.toLowerCase().includes('syntax error')
    ) {
      return toast.error(
        'Falha ao persistir o registro na base de dados, contate o suporte para resolução.',
      );
    }

    for (let i = 0; i < errorKeywords.length; i++) {
      if (message && message.includes(errorKeywords[i])) {
        arrMessage = message.split(errorKeywords[i]);
        message = arrMessage[1].trimLeft() || message;
        break;
      }
    }

    switch (status) {
      case 500:
        toast.error(message);
        break;
      case 401:
        toast.error(message);
        break;
      case 206:
        toast.warning(message);
        break;
      default:
        toast.warning(message);
        break;
    }
    throw error.response;
  },
);

export default api;
